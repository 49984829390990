<template>
  <div>
    <div class="d-flex flex-row align-items-center">
    <h1>Inventory</h1>
    <a :href="website" class="btn btn-sm btn-link ms-auto">{{website}}</a>
    </div>

    <static-alert v-if="errors" :message="errors" @on-hide="errors=''" />

    <div class="mb-5">
      <item-feed />
    </div>
  </div>
</template>

<script setup>
import ItemFeed from "@/components/ItemFeed.vue";
import {computed} from "vue";
import {useStore} from "vuex"
import StaticAlert from "@/components/Utils/StaticAlert.vue";
const store = useStore()

const website = computed(() => store.getters["eshop/config"]('WEBSITE_URL'))
</script>

<style lang="scss" scoped>

</style>
