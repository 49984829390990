<template>
  <transition mode="out-in">
    <loading-spinner v-if="loading" :small="true" class="mx-1" @click.stop />
    <span role="button" v-else-if="imported" class="px-2 text-success" @click.stop="psuedoCall">&checkmark;</span>
    <span role="button" v-else class="px-2 rounded border border-info text-info bg-info-subtle" @click.stop="onAddInput(input)">&plus;</span>
  </transition>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue'
import { useStore } from 'vuex'
import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";

const loading = ref(true)
const store = useStore()
const imported = ref(false)
const notes = computed(() => JSON.parse(props.input.notes || '{}'))
const props = defineProps({
  input: {
    type: Object,
    required: true,
    default: () => {}
  }
})

if (props.input && props.input.externalId && notes.value.status === 'NEW') {
  store.dispatch('eshop/checkTransaction', {key: props.input.externalId})
      .then(exists => {
        imported.value = !!exists
        loading.value = false
      })
} else {
  imported.value = true
  loading.value = false
}

async function psuedoCall() {
  loading.value = true
  await new Promise(resolve => setTimeout(resolve, 1000))
  loading.value = false
}

function onAddInput(input) {
  loading.value = true
  store.dispatch('eshop/addWaveInput', input)
      .then(info => info.data)
      .then(data => data.moneyTransactionCreate)
      .then(data => {
        if (data.didSucceed) {
          return store.dispatch('eshop/mapTransaction', {transactionId: input.externalId})
        } else if (data.inputErrors) {
          const alreadyExists = data.inputErrors.filter(e => e.message === "A transaction with the same externalId already exists.")
          console.log(data.inputErrors, alreadyExists)
          if (alreadyExists.length > 0) {
            return store.dispatch('eshop/mapTransaction', {transactionId: input.externalId})
          }
        } else {
          return Promise.resolve(false)
        }
      })
      .then(exists => {
        imported.value = !!exists
        loading.value = false
      })
      .catch(e => {
        alert((e.response && e.response.data && e.response.data.error) || e.message || JSON.stringify(e))
        loading.value = false
      })
}

</script>

<style lang="scss" scoped>

</style>
