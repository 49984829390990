<template>
  <div class="card shadow shadow-sm mb-4" :class="{'card-expanded':expanded}">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span class="me-2 fw-bold">{{ account.name }}</span>



      <money-block v-if="!expanded" :dollar="account.balance" :danger="danger()" class="ms-auto fw-bold" />
      <small class="ms-2 badge border text-dark rounded-pill"
             :class="{'bg-success-subtle border-success': account.type.value === 'INCOME' || account.type.value === 'ASSET', 'bg-danger-subtle border-danger' : account.type.value === 'EXPENSE'}"
      >{{ account.type.value }}</small>
    </div>
    <div v-if="expanded" class="card-body d-flex">
      <money-block :danger="danger()" :dollar="account.balance" class="mx-auto display-4" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from 'vue'
import MoneyBlock from "@/components/Utils/MoneyBlock.vue";
const props = defineProps({
  account: Object,
  expanded: {type: Boolean, default: false},
})
const { account } = toRefs(props)

function danger() {
  return (account.value.type.value === 'EXPENSE' && parseFloat(account.value.balance) > 0) || parseFloat(account.value.balance) < 0
}
</script>

<style>
.card-expanded .card-body {line-height: 3rem;}
</style>
