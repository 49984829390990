<template>
  <div>
    <h1>Remove eShop</h1>
    <p class="lead">
      Permanently remove/delete eShop from your eShopCommand account.
    </p>
    <form @submit.prevent="deleteStore">
      <div class="form-group mb-3">
        <label class="mb-3">Type <strong>{{ storeName }}</strong> to remove</label>
        <input type="text" v-model="storeConfirm" class="form-control">
      </div>
      <div class="form-group mb-3">
        <button type="submit" class="btn btn-danger" :disabled="disabled">Confirm Deletion</button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue"
const store = useStore()
const auth0 = useAuth0()
const router = useRouter()
const storeConfirm = ref('')
const eShop = computed(() => store.state.eshop.store)
const storeName = computed(() => eShop.value.name)
const disabled = computed(() => storeName.value !== storeConfirm.value)
function deleteStore() {
  if (disabled.value) return
  try {
    store.dispatch("deleteStore", {auth0, eShop: eShop.value})
        .then(() => router.replace({to: "Welcome"}))
        .catch(e => {
          alert(e.response.data.error || e.message || JSON.stringify(e))
        })
  } catch (e) {
    alert(e.message || JSON.stringify(e))
  }
}

</script>

<style lang="scss" scoped>

</style>
