<template>
  <div>

<!--    <recent-transactions transaction-type="payouts" />-->

    <ebay-transactions @on-update="loadTransactions" class="mb-3"/>

    <div class="d-flex mb-3  align-content-center flex-wrap d-none">
      <div
          v-for="type in ['SHIPPING_LABEL','REFUND','NON_SALE_CHARGE','SALE','DISPUTE','CREDIT']"
          :key="type" type="button"
          class="badge badge-pill m-2 flex-fill"
          :class="{'bg-success-subtle text-dark border border-success': excludeTypes.indexOf(type) === -1, 'bg-light border border-dark text-dark': excludeTypes.indexOf(type) !== -1}"
          @click="e=>toggleType(e,type)"
      >
        {{ type}}
      </div>
    </div>

    <transition mode="out-in">
      <div v-if="loading" class="p-5 text-center">
        <loading-spinner />
      </div>

      <div v-else>
        <paginator-bar :total-items="totalRecords" :per-page="perPage" :page="page" @next-page="nextPage" />
        <table class="table table-hover table-responsive">
          <tbody>
          <transaction-row v-for="input in transactions" :key="input.externalId" :input="input" />
          </tbody>
        </table>
        <paginator-bar :total-items="totalRecords" :per-page="perPage" :page="page" @next-page="nextPage" />
      </div>

    </transition>


  </div>
</template>

<script setup>
import {computed, ref, nextTick} from "vue"
import {useStore} from "vuex"
import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";
import TransactionRow from "@/components/TransactionRow.vue";
import PaginatorBar from "@/components/Utils/PaginatorBar.vue";
import EbayTransactions from "@/components/EbayTransactions.vue";

const store = useStore()
const loading = ref(true)
const perPage = ref(25)
const page = ref(1)
const excludeTypes = ref([])

const loadedTransactions = ref([])
const transactions = computed(() => {
  if (!loadedTransactions.value) return []
  return loadedTransactions.value.slice().filter(t => excludeTypes.value.indexOf(JSON.parse(t.notes).transactionType) === -1)
})

const totalRecords = computed(() => store.state.eshop.transactionCount)

function toggleType(e, type) {
  if (excludeTypes.value.indexOf(type) !== -1) {
    excludeTypes.value = excludeTypes.value.filter(t => t !== type)
  } else {
    excludeTypes.value.push(type)
  }
}

function nextPage(dir) {
  page.value += dir
  loadTransactions()
}

// loadTransactions()

async function loadTransactions() {
  loading.value= true
  loadedTransactions.value = []
  await nextTick()
  store.dispatch('eshop/getTransactions', { page:page.value, perPage:perPage.value })
      .then(results => {
        loadedTransactions.value = results
        loading.value= false
      })
      .catch(e => {
        loading.value= false
        alert(e.message || e)
      })
}

</script>
