<template>
  <div>
    <transition mode="out-in">
      <div v-if="saving">
        <loading-spinner />
      </div>
      <div v-else-if="editMode">
        <form @submit.prevent="addConfigKey">
          <div class="mb-3">
            <input type="text" class="form-control" @change="onChange" v-model="keyValue" placeholder="CONFIG_KEY_NAME">
          </div>
          <div class="text-end">
            <button class="btn btn-link" type="button" @click="toggle">Cancel</button>
            <button class="btn btn-primary ms-3" type="submit">Save</button>
          </div>
        </form>
      </div>
      <div v-else class="text-end">
        <button class="btn btn-primary" @click="toggle">Add Config Key</button>
      </div>
    </transition>
  </div>
</template>

<script setup>
import {ref} from "vue";
import { useStore } from "vuex"
import { useAuth0 } from "@auth0/auth0-vue";
import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";

const store = useStore()
const auth0 = useAuth0()
const saving = ref(false)
const editMode = ref(false)
const keyValue = ref('')

function toggle() {
  editMode.value = !editMode.value
  keyValue.value = ''
}

function onChange() {
  keyValue.value = keyValue.value.toString().replace(/\W/g,'_').toUpperCase()
}

function addConfigKey() {
  saving.value = true
  store.dispach('admin/saveConfigKey', {auth0, val: keyValue.value})
      .then(() => {
        editMode.value = false
        keyValue.value = ''
        saving.value = false
      })

}

</script>

<style lang="scss" scoped>

</style>
