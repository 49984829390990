<template>
  <div>
    <static-alert v-if="message" :message="message" @on-hide="message=''" />
    <div class="row mb-3 justify-content-center">
      <div class="col-10 mb-3 col-sm-5 mb-sm-0 order-last order-md-first text-center">
        <input
            type="range"
            v-model="range"
            :min="(new Date('2024-07-01')).getTime()"
            :max="(new Date()).getTime()" class="form-range"
            :step="interval.step"
            @change="setRange"
        ><div class="small text-muted d-relative" style="transform:translateY(-10px);">{{ (new Date(parseInt(range))).toLocaleDateString() }}</div>
      </div>

      <div class="col-10 my-4 col-md-4 my-md-0 d-flex justify-content-center order-first order-md-last justify-content-md-end">
        <i
            v-for="int in intervals"
            :key="int.dateFormat"
            @click="setInterval(int)"
            class="bi bi-enlarged me-4"
            :class="`${int.icon} ${int.icon===interval.icon?'bg-success text-white':'border border-success bg-success-subtle text-success'}`"
            :title="int.label"
            role="button"
        >
        </i>
      </div>

    </div>

    <apexchart
        :options="salesOptions"
        :series="salesSeries"
        @click="onMarkerClick"
        class="chart"
    ></apexchart>




  <table class="table table-responsive table-sm d-none">
    <tbody v-for="(trans, idx) in salesReport" :key="idx">
      <tr v-if="idx===0">
        <th v-for="key in Object.keys(trans)" :key="key">{{ key }}</th>
      </tr>
      <tr>
        <td v-for="key in Object.keys(trans)" :key="key">{{ trans[key] }}</td>
      </tr>
    </tbody>
  </table>
  </div>
</template>

<script setup>
import {computed, nextTick, ref} from 'vue'
import { useStore } from 'vuex'
import StaticAlert from "@/components/Utils/StaticAlert.vue";

const store = useStore()
const salesReport = ref([])
const range = ref((new Date('2024-07-01')).getTime())
const message = ref('')

function getEarlierDate(daysEarlier) {
  const date = new Date();
  const minDate = new Date('2024-07-01');
  const newDate = new Date(date);
  newDate.setDate(date.getDate() - daysEarlier);
  return newDate > minDate ? newDate.toISOString().substring(0,10) : minDate.toISOString().substring(0,10);
}

  const intervals = ref([
    {
      label: 'Past Year by Month',
      frequency: 'by Month',
      dateFormat: '%Y-%m',
      startDate: getEarlierDate(365),
      step: 86400 * 1000 * 30,
      icon: 'bi-bar-chart-line-fill'
    },
    {
      label: 'Past Year by Week',
      frequency: 'by Week',
      dateFormat: '%Y-%v',
      startDate: getEarlierDate(365),
      step: 86400 * 1000 * 7,
      icon: 'bi-calendar'
    },
    {
      label:'Past 31 Days',
      frequency: 'by Day',
      dateFormat:'%Y-%m-%d',
      startDate: getEarlierDate(31),
      step: 86400 * 1000 * 7,
      icon: 'bi-calendar-month',
    },
    {
      label:'Past 7 Days',
      frequency: 'by Day',
      dateFormat:'%Y-%m-%d',
      startDate: getEarlierDate(7),
      step: 86400 * 1000 * 7,
      icon: 'bi-calendar-week',
    },
  ])

  const interval = ref()
  const startDate = ref()

  const salesSeries = computed(() => ([
    {
      name: 'expenses',
      type: 'bar',
      data: salesReport.value.map(c => (parseFloat(c.shipping || 0)
          + parseFloat(c.refunds || 0)
          + parseFloat(c.fees || 0)
          + parseFloat(c.marketing || 0)
          + parseFloat(c.disputes || 0)).toFixed(2)),
    },
    {
      name: 'income',
      type: 'bar',
      data: salesReport.value.map(c => (parseFloat(c.gross_sales || 0)
          + parseFloat(c.credits || 0)).toFixed(2))
    },
    {
      name: 'net',
      type: 'line',
      data: salesReport.value.map(c => parseFloat(c.net_sales || 0).toFixed(2)),
    }
    ]))
  const salesOptions = computed(() => ({
    chart: {
      height: "500",
      type: "line",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      }
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [2],
      formatter: function (val) {
        return `${val.toFixed(2)}`
      },
      style: {
        fontSize: '18px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'normal',
        colors: undefined
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        padding: 2,
        borderRadius: 4,
        borderWidth: 0,
        //borderColor: '#fff',
        opacity: 0.9,
        // dropShadow: {
        //   enabled: false,
        //   top: 1,
        //   left: 1,
        //   blur: 1,
        //   color: '#000',
        //   opacity: 0.45
        // }
      },
    },
    title: {
      text: 'Sales & Expense ' + interval.value.frequency + ' Since ' + startDate.value
    },
    labels: salesReport.value.map(c => c.sale_date),
    colors: ["#CC0000","#006600","#0000CC"],
    stroke: {width: [0, 0, 6]}
  }))

  setInterval(intervals.value[3])

  async function setRange() {
    await nextTick()
    const newInterval = { ...interval.value, startDate: new Date(parseInt(range.value)).toISOString().substring(0,10) }
    //console.log('new date range', newInterval)
    setInterval(newInterval)
  }

  function setInterval(newInterval) {
      interval.value = newInterval
      startDate.value = newInterval.startDate
      range.value = (new Date(newInterval.startDate)).getTime()
      loadSales()
  }

  function onMarkerClick(e, chartEvt, opts) {
    console.log(chartEvt, opts, opts.seriesIndex, salesSeries.value[opts.seriesIndex])
  }

  async function loadSales () {
    await nextTick()
    store.dispatch('eshop/getSales', { fromDate: startDate.value, interval: interval.value.dateFormat})
        .then(data => {
          salesReport.value = data
        }).catch(error => {
          message.value = error.response?.data || error.message || error
          console.log('getSales error', error)
        })
  }
</script>

<style lang="scss" scoped>
  .chart {min-height: 50rem;}
</style>


