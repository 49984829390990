module.exports = class eBay {
  config = {}
  constructor(config) {
    this.config = config || {}
    this.getInput = this.getInput.bind(this)
    this.mapInputs = this.mapInputs.bind(this)
    this.transactionsToInputs = this.transactionsToInputs.bind(this)
    this.mapEbayTransactions = this.mapEbayTransactions.bind(this)
    this.testInput = this.testInput.bind(this)
    this.processInput = this.processInput.bind(this)
    this.processMultipleInputs = this.processMultipleInputs.bind(this)
    this.parseNumber = this.parseNumber.bind(this)
  }

  transactionsToInputs(transactions) {
    return this.mapEbayTransactions(transactions)
      .then(this.mapInputs)
  }

  mapInputs(trans) {
    return Promise.resolve(trans.map(item => this.getInput(item)))
  }

  testInput(input) {
    if (!input.anchor.amount) return false
    if (!input.lineItems[0]) return false

    let lineItemsTotal = input.lineItems[0].amount
    const copy = input.lineItems.slice()
    copy.shift()
    copy.forEach(lineItem => {
      lineItemsTotal -= lineItem.amount
    })

    return this.parseNumber(lineItemsTotal) === Math.abs(this.parseNumber(input.anchor.amount));
  }

  processMultipleInputs(inputs) {
    return Promise.allSettled(inputs.map(input => {
      return this.processInput(input)
    }))
  }

  processInput(input) {
    if (!this.testInput(input)) {
      return Promise.reject(new Error(`Invalid input: ${JSON.stringify(input)}`))
    }

    return this.addTransaction(input)
      .then(response => response.json())
      .then(result => result.data)
      .then(data => data.moneyTransactionCreate)
      .then(transaction => {
        if (transaction.didSucceed) {
          return transaction.transaction
        } else {
          return 'transaction failed: ' + JSON.stringify(transaction.inputErrors)
        }
      })
  }

  parseNumber(num) {
    if (num === '--' || !num) return 0
    return +parseFloat(num).toFixed(2)
  }

  mapEbayPayout(payout) {
    /**
     {
     "payoutId": "6598021690",
     "payoutStatus": "SUCCEEDED",
     "payoutStatusDescription": "Funds sent",
     "amount": {
     "value": "82.78",
     "currency": "USD"
     },
     "payoutDate": "2024-10-22T09:37:29.000Z",
     "lastAttemptedPayoutDate": "2024-10-24T11:06:08.768Z",
     "transactionCount": 4,
     "payoutInstrument": {
     "instrumentType": "BANK",
     "nickname": "Capital One Bank",
     "accountLastFourDigits": "1768"
     },
     "payoutMemo": "Payout takes 1 to 3 business days, to arrive depending on your bank",
     "bankReference": "XEQHYYSQWUO1U6C"
     }
     **/
    return {
      type: `PAYOUT ${payout.payoutStatus || ''}`,
      externalId: payout.payoutId,
      date: payout.payoutDate.split('T')[0],
      description: `${payout.payoutStatusDescription || ''} ${payout.payoutMemo || ''}`,
      salesAmount: 0,
      shippingAmount: 0,
      feeAmount: 0,
      grossAmount: this.parseNumber(payout.amount.value || 0),
      netAmount: this.parseNumber(payout.amount.value || 0),
      notes: JSON.stringify(payout)
    }
  }

  mapEbayTransactions(transactions) {
    return Promise.resolve(
      transactions.map(transaction => {
        if ('payoutDate' in transaction) return this.mapEbayPayout(transaction)
        const MULTIPLIER = transaction.bookingEntry === 'DEBIT' ? -1 : 1
        const date = (new Date(new Intl.DateTimeFormat('en-US', {
          timeZone: 'America/Chicago',
          dateStyle: 'short',
          // year: "numeric",
          // month: "2-digit",
          // day: "2-digit",
          // hour: "2-digit",
          // minute: "2-digit",
          // second: "2-digit",
          // fractionalSecondDigits: 3,
          // hour12: false,
        }).format(new Date(transaction.transactionDate)))).toISOString().split('T')[0]
        // transaction.transactionDate.split('T')[0]
        switch(transaction.transactionType) {
          /**
           * amount: {value: "0.25", currency: "USD"}
           * bookingEntry:"DEBIT"
           * feeType:"INSERTION_FEE"
           * payoutId:"6539731354"
           * references:[{referenceId: "226353543147", referenceType: "ITEM_ID"}]
           * salesRecordReference:"0"
           * transactionDate:"2024-09-15T18:01:11.952Z"
           * transactionId:"FEE-6271170175516_11"
           * transactionStatus:"PAYOUT"
           * transactionType:"NON_SALE_CHARGE"
           */
          case 'NON_SALE_CHARGE':
            return {
              type: transaction.transactionType,
              externalId: transaction.transactionId,
              date: date,
              description: transaction.feeType + ' for ' + (transaction.transactionMemo || (transaction.references && transaction.references.map(r=>r.referenceId).join(' '))),
              salesAmount: 0,
              shippingAmount: 0,
              feeAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              grossAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              netAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              notes: JSON.stringify(transaction)
            }
          case 'SALE':
            return {
              type: transaction.transactionType,
              externalId: transaction.transactionId,
              date: date,
              description: transaction.transactionType + ' to ' + transaction.buyer.username + ' Order ' + transaction.orderId,
              salesAmount: this.parseNumber((transaction.totalFeeBasisAmount.value || 0) * MULTIPLIER),
              shippingAmount: 0,
              feeAmount: this.parseNumber((transaction.totalFeeAmount.value || 0) * MULTIPLIER),
              grossAmount: this.parseNumber((transaction.totalFeeBasisAmount.value || 0) * MULTIPLIER),
              netAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              notes: JSON.stringify(transaction)
            }
          case 'REFUND':
          // case 'CREDIT':
          // case 'DISPUTE':
            return {
              type: transaction.transactionType,
              externalId: transaction.transactionId,
              date: date,
              description: transaction.transactionType + ' for ' + transaction.references.map(r=>r.referenceId).join(' '),
              salesAmount: this.parseNumber((transaction.totalFeeBasisAmount.value || 0) * MULTIPLIER),
              shippingAmount: 0,
              feeAmount: this.parseNumber((transaction.totalFeeAmount.value || 0) * MULTIPLIER),
              grossAmount: this.parseNumber((transaction.totalFeeBasisAmount.value || 0) * MULTIPLIER),
              netAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              notes: JSON.stringify(transaction)
            }
          case 'TRANSFER':
            return {
              type: transaction.transactionType,
              externalId: 'transfer' + transaction.transactionId,
              date: date,
              description: transaction.transactionMemo || transaction.transactionType,
              salesAmount: 0,
              shippingAmount: 0,
              feeAmount: 0,
              grossAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              netAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              notes: JSON.stringify(transaction)
            }
          default:
            return {
              type: transaction.transactionType,
              externalId: transaction.transactionId,
              date: date,
              description: (transaction.transactionMemo || transaction.transactionType) + ' for ' + (transaction.orderId || '--'),
              salesAmount: 0,
              shippingAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              feeAmount: 0,
              grossAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              netAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              notes: JSON.stringify(transaction)
            }
        }

      })
    )
  }

  getInput({type, externalId, date, description, notes, netAmount, grossAmount, feeAmount}) {
    const input = {"businessId": this.config.WAVE_BUSINESS_ID || '',
      "externalId": externalId,
      "date": date,
      "description": description,
      "notes": notes,
      "anchor": {
        "accountId": this.config.WAVE_ANCHOR_ACCOUNT_ID || '',
        // "escAccountId": this.config.ANCHOR_ACCOUNT_ID || 0,
        "amount": Math.abs(netAmount),
        "direction": netAmount < 0 ? "WITHDRAWAL" : "DEPOSIT"
      },
      "lineItems": []
    }
    switch(type) {
      case 'Order':
      case 'SALE':
      case 'REFUND':
        input.lineItems.push({
          "accountId": this.config.WAVE_SALES_ACCOUNT_ID || '',
          // "escAccountId": this.config.SALES_ACCOUNT_ID || 0,
          "amount": Math.abs(grossAmount),
          "balance": grossAmount < 0 ? "DECREASE" : "INCREASE"
        })
        input.lineItems.push({
          "accountId": this.config.WAVE_EBAY_FEES_ACCOUNT_ID || '',
          // "escAccountId": this.config.EBAY_FEES_ACCOUNT_ID || 0,
          "amount": Math.abs(feeAmount),
          "balance": feeAmount > 0 ? "INCREASE" : "DECREASE"
        })
        break;
      case 'Other fee':
      case 'OTHER_FEE':
      case 'OTHER_EBAY_FEE':
      case 'NON_SALE_CHARGE':

        input.lineItems.push({
          "accountId": description.indexOf('AD_FEE') >= 0 ?
            this.config.WAVE_AD_FEES_ACCOUNT_ID || '' :
            this.config.WAVE_EBAY_FEES_ACCOUNT_ID || '',
          // "escAccountId": description.indexOf('AD_FEE') >= 0 ?
          //   this.config.AD_FEES_ACCOUNT_ID || 0 :
          //   this.config.EBAY_FEES_ACCOUNT_ID || 0,
          "amount": Math.abs(netAmount),
          "balance": netAmount < 0 ? "INCREASE" : "DECREASE"
        })
        break;
      case 'Shipping label':
      case 'SHIPPING_LABEL':
        input.lineItems.push({
          "accountId": this.config.WAVE_SHIPPING_ACCOUNT_ID || '',
          // "escAccountId": this.config.SHIPPING_ACCOUNT_ID || 0,
          "amount": Math.abs(netAmount),
          "balance": netAmount < 0 ? "INCREASE" : "DECREASE"
        })
        break;
      case 'Payout':
      case 'PAYOUT':
        input.lineItems.push({
          "accountId": this.config.WAVE_CAPITAL_ONE_ID || '',
          // "escAccountId": this.config.BANK_ACCOUNT_ID || 0,
          "amount": Math.abs(netAmount),
          "balance": netAmount < 0 ? "INCREASE" : "DECREASE"
        })
        break;

    }

    return input
  }

}
