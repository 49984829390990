import axios from 'axios'

export default class AxiosService {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL: baseURL,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      timeout: 30000,
      withCredentials: false,
    });

    this.axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error.response?.status === 401 || (error.status || error.code || 500) === 401) {
        console.log('response error', error)
        const e = new Error('Authentication failed ' + (error.response?.data || error.message || ''));
        e.status = 401
        return Promise.reject(e)
      }
      return Promise.reject(error);
    });
  }

  setToken(token) {
    this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return this.axios
  }
}
