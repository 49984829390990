<template>
  <transition mode="out-in">
    <loading-spinner v-if="fetching" />
    <div v-else>

      <div v-if="inSync === SYNC_NONE">
        <div class="lead mb-3">
          <i class="bi bi-exclamation-triangle text-warning me-2"></i>
          You Don't Have an eBay Store to Imported.
        </div>
      </div>

      <div v-else-if="inSync === SYNC_TRUE">
        <div class="lead mb-3">
          <i class="bi bi-check-circle text-success me-2"></i>
          eBay Store Imported.
        </div>
        <div class="ms-5">
        <e-shop-card :eshop="storeDetails" class="mb-3">
          <template #header>
            <i class="bi bi-box-arrow-in-down me-2"></i>
            Imported eBay Store
          </template>
        </e-shop-card>
        </div>
      </div>

      <div v-else-if="inSync === SYNC_NO_IMPORT">

        <div class="lead mb-3">
          <i class="bi bi-circle text-info me-2"></i>
          Next: Import Your eBay Store.
        </div>

        <div class="ms-5">
          <e-shop-card :eshop="storeDetails" class="mb-3">
            <template #header>
              <i class="bi bi-box-arrow-in-down me-2"></i>
              Ready to Import:
            </template>
          </e-shop-card>

          <button type="button"
                  class="btn btn-primary"
                  @click="syncUp">
            <i class="bi bi-box-arrow-in-down me-2"></i> Import/Connect eBay Store
          </button>
        </div>
      </div>

      <div v-else-if="inSync === SYNC_DIFFERENT">
        <div class="lead mb-3">
          <i class="bi bi-check-circle text-warning me-2"></i>
          eBay Store Imported - Needs Updating.
          <button type="button" class="ms-3 btn btn-sm btn-light btn-outline-primary" @click="syncUp">
            <i class="bi bi-arrow-down-up me-2"></i>
            Resync Now
          </button>
        </div>

        <div class="ms-5">
          <e-shop-card :eshop="storeDetails" class="mb-3">
            <template #header>
              <i class="bi bi-box-arrow-in-down me-2"></i>
              Imported eBay Store
            </template>
          </e-shop-card>

          <div class="text-center mb-3">
            <i class="display-1 bi bi-arrow-down-up"></i>
          </div>

          <e-shop-card v-if="eshop" :eshop="eshop" class="mb-3">
            <template #header>Imported eShop (Needs Updataing)</template>
          </e-shop-card>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import EShopCard from "@/components/eShopCard.vue";
import {computed, ref, defineEmits} from "vue";
import {useStore} from "vuex";
import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";

const SYNC_NONE = 'none'
const SYNC_NO_IMPORT = 'no-import'
const SYNC_DIFFERENT = 'diff'
const SYNC_TRUE = 'in-sync'

const store = useStore()
const storeDetails = ref()
const fetching = ref(false)

const eshop = computed(() => store.state.eshop.store)
const inSync = computed(() => {
  if (!storeDetails.value) return SYNC_NONE
  if (!eshop.value) return SYNC_NO_IMPORT
  if (eshop.value.id !== storeDetails.value.URLPath) return SYNC_DIFFERENT
  if (eshop.value.name !== storeDetails.value.Name) return SYNC_DIFFERENT
  if (eshop.value.description !== storeDetails.value.Description) return SYNC_DIFFERENT
  if (eshop.value.logoUrl !== storeDetails.value.Logo?.URL) return SYNC_DIFFERENT
  return SYNC_TRUE
})

const emits = defineEmits(['onError'])
getEbayStoreDetails()

function getEbayStoreDetails(sync) {
  fetching.value = true
  store.dispatch('eshop/getEbayStoreDetails', { syncData: !!sync })
      .then(details => {
        storeDetails.value = details
        fetching.value = false
      })
      .catch(error => {
        emits('onError', error.response?.data || error.message || error)
        fetching.value = false
      })
}

function syncUp() {
  getEbayStoreDetails(true)
}

</script>

<style lang="scss" scoped>

</style>
