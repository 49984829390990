<template>
  <div class="container container-container d-flex flex-column align-items-center justify-content-center" style="min-height:100svh;">
    <div class="welcome-logo mb-4">
      <img src="@/assets/logo-only.svg" class="img-fluid" alt="eShopCommand">
    </div>
    <div class="display-5 mb-4">{{ appName }}</div>
    <div class="mb-4 text-muted"><em>{{ tagLine }}</em></div>
    <login-button />
  </div>
</template>

<script setup>
import { computed } from "vue"
import { useStore } from "vuex"
import LoginButton from "@/components/LoginButton.vue";
const store = useStore();
const appName = computed(() => store.state.appName);
const tagLine = computed(() => store.state.tagLine);

</script>


<style scoped lang="scss">
.welcome-logo {
  width: 100%;
  max-width: 150px;
}
</style>
