<template>

  <transition mode="out-in">
    <div v-if="fetching">

      <div class="p-4 bg-placeholder border rounded mb-3"></div>
      <div class="p-4 bg-placeholder border rounded mb-3"></div>

      <div class="border bg-placeholder d-square">
        <div class="d-flex align-items-center justify-content-center">
          <loading-spinner />
        </div>
      </div>
    </div>
    <div v-else-if="itemDetails">
      <div class="lead">
        <a :href="itemDetails.ListingDetails?.ViewItemURL" target="_blank" class="text-decoration-none">
          {{ itemDetails.Title }}
        </a>
      </div>
      <div class="mb-3 lead fw-bold">
        <strong :class="{'text-decoration-line-through': line.lineItemCost?.value !== itemDetails.SellingStatus?.CurrentPrice?.value}">${{ parseFloat(itemDetails.SellingStatus?.CurrentPrice?.value).toFixed(2) }}</strong>
        <small v-if="itemDetails.SellingStatus?.HighBidder" class="ms-2 text-success">
          SOLD &times; {{ line.quantity }}
          <span v-if="!discreet">to
          {{ itemDetails.SellingStatus?.HighBidder?.UserID }}
          </span>
          for <strong>${{ parseFloat( line.lineItemCost?.value ).toFixed(2) }}</strong>
        </small>
      </div>

      <image-gallery
        v-if="itemDetails.PictureDetails?.PictureURL"
        :images="itemDetails.PictureDetails?.PictureURL"
        :auto-play="discreet"
        class="border rounded overflow-hidden" />

      <div v-if="!discreet" class="row small">
        <div class="col-12 col-md-6 mb-3 mb-md-0">
          <div class="p-1 rounded bg-info-subtle">
            <div>ID: <strong>{{ itemDetails.ItemID }}</strong></div>
            <div>SKU: <strong>{{ itemDetails.SKU }}</strong></div>
            <div>Weight:
            <strong>
              {{ itemDetails.ShippingPackageDetails?.WeightMajor?.value }}
              {{ itemDetails.ShippingPackageDetails?.WeightMajor?.unit }}
              {{ itemDetails.ShippingPackageDetails?.WeightMinor?.value }}
              {{ itemDetails.ShippingPackageDetails?.WeightMinor?.unit }}
            </strong>
          </div>
          </div>
        </div>
        <div class="col-12 col-md-6" v-if="line.quantity">
          <div class="fw-bold">Quantity X {{ line.quantity }}</div>
          <div class="d-flex">
            <div class="me-3 fw-bold">Price</div>
            <div class="ms-auto">${{ parseFloat(line.lineItemCost?.value).toFixed(2) }} </div>
          </div>
          <div class="d-flex">
            <div class="me-3 fw-bold">Shipping</div>
            <div class="ms-auto">${{ parseFloat(line.deliveryCost?.shippingCost?.value || 0).toFixed(2) }}</div>
          </div>
          <div class="d-flex" v-if="line.deliveryCost?.discountAmount">
            <div class="ms-2 me-3 fw-bold">Discount</div>
            <div class="ms-auto">${{ -parseFloat(line.deliveryCost?.discountAmount?.value || 0).toFixed(2) }}</div>
          </div>
          <div class="d-flex">
            <div class="me-3 fw-bold">Total</div>
            <div class="ms-auto fw-bold">${{ parseFloat(line.total?.value || 0).toFixed(2)}}</div>
          </div>
          <hr>
          <div class="fw-bold">Collected by eBay</div>
          <div class="d-flex" v-if="line.taxes && line.taxes.length">
            <div class="me-3 fw-bold">Taxes</div>
            <div class="ms-auto">${{ line.taxes.reduce((v, t) => v + parseFloat(t.amount?.value || 0), 0).toFixed(2)}}</div>
          </div>
          <div class="d-flex" v-if="line.ebayCollectAndRemitTaxes">
            <div class="me-3 fw-bold">Taxes</div>
            <div class="ms-auto">${{ line.ebayCollectAndRemitTaxes.reduce((v, t) => v + parseFloat(t.amount?.value || 0), 0).toFixed(2)}}</div>
          </div>
        </div>
      </div>


    </div>


    <div v-else>
      {{ props.line }}
    </div>
  </transition>

</template>

<script setup>
  import { defineProps, ref, nextTick } from "vue"
  import { useStore } from "vuex"

  import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";

  import ImageGallery from "@/components/Utils/ImageGallery.vue";

  const props = defineProps( {
    discreet: {type: Boolean, default: false},
    line: {
      type: Object,
      required: true,
      default: () => {}
    }
  } )
  const store = useStore()
  const fetching = ref(true)
  const itemDetails = ref(null)

  getItem()

  async function getItem() {
    await nextTick()
    const itemId = props.line?.legacyItemId || props.line?.ItemID
    if (!itemId) return
    fetching.value = true
    store.dispatch('eshop/getEbayItem', {itemId})
        .then(res => {
          itemDetails.value = res
          fetching.value = false
        })
        .catch(err => {
          itemDetails.value = err
          fetching.value = false
        })
  }
</script>

<style lang="scss" scoped>
.d-left {width: 100%; max-width: 150px;}
</style>
