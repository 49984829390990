


<template>
  <div>
    <transition mode="out-in">
      <div v-if="isAuthenticated">
        <stores />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import {useAuth0} from "@auth0/auth0-vue";
import Stores from "@/components/Stores.vue";
const auth0 = useAuth0();

const isAuthenticated = ref(auth0.isAuthenticated);
</script>

<style scoped>
.home-content {
  width: 500px; max-width: 100%;
}
</style>
