<template>
  <div>
    <static-alert v-if="message" :message="message" @on-hide="message=''" />
    <transition mode="out-in">
      <loading-spinner v-if="fetching" class="m-3" />
      <div v-else-if="token">
        <div class="lead mb-3">
          <i class="bi bi-check-circle text-success me-2"></i>
          Wave (waveapps.com) Authorized.
        </div>
      </div>
      <div v-else-if="code" class="mb-3">
        <loading-spinner :small="true" class="me-2" />
        Authorizing Wave (waveapps.com) code
      </div>
      <div v-else class="mb-3">
        <connection-wave-apps />
      </div>
    </transition>
  </div>
</template>

<script setup>
import {ref, defineProps, defineEmits} from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";
import StaticAlert from "@/components/Utils/StaticAlert.vue";
import ConnectionWaveApps from "@/components/waveapps/ConnectionWaveApps.vue";

const store = useStore()
const route = useRoute()
const emits = defineEmits(['onAuth'])

defineProps(['importable'])

const fetching = ref(false)
const message = ref('')
const token = ref('')
const code = route.query.code || null

if (code) {
  getToken()
} else {
  checkWaveapps()
}

function setMessage(msg) {
  message.value = msg
}

function getToken() {
  store.dispatch('eshop/getOauthTokenFromCode', {
    oauth_vendor: 'waveapps',
    code
  })
      .then(checkWaveapps)
    .catch(error => {
      setMessage(  error.response?.data || error.message || error)
    })
}

function checkWaveapps() {
  fetching.value = true

  store.dispatch("eshop/getOauthCheckToken", {
    oauth_vendor: 'waveapps'
  })
    .then((t) => {
      token.value = t
      emits('onAuth', !!t)
      fetching.value = false
      //if (code) await router.replace({name: 'Welcome'})
    }).catch(error => {
      message.value = error.response?.data || error.message || error
      fetching.value = false
    })
}
</script>

<style scoped>

</style>
