<template>
  <div>
  <transition mode="out-in">
    <div v-if="isLoading" class="container container-container d-flex align-items-center justify-content-center" style="min-height:100svh;">
      <loading-spinner />
    </div>
    <div v-else-if="isAuthenticated">
      <nav-bar />
      <div class="app-body">
      <transition mode="out-in">
        <router-view/>
      </transition>
      </div>
    </div>
    <welcome v-else />
  </transition>


  </div>

</template>

<script setup>
import NavBar from '@/components/NavBar.vue'
import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";
import { useAuth0 } from "@auth0/auth0-vue"
import { jwtDecode } from "jwt-decode";
import { useStore } from "vuex"
import { watch } from "vue"
import Welcome from "@/views/Welcome.vue";

const store = useStore()
const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()

watch(isLoading, async (newValue) => {
  if (!newValue && isAuthenticated) {
    await loadRoles()
  }
});

async function loadRoles() {
  try {
    const silentToken = await getAccessTokenSilently()
    store.commit("setApi", silentToken)
    const token = jwtDecode(silentToken)
    store.commit('setRoles', token.permissions || [])
  } catch (e) {
    console.error(e)
    store.commit('setRoles', [])
  }
}

</script>

<style lang="scss">

</style>
