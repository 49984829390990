<template>
  <div>
    <h1>ADMIN - Config Keys</h1>

    <static-alert v-if="message" :message="message" @on-hide="message=''" />

    <div class="mb-5">
      <config-keys-form />
    </div>

    <div class="list-group">
      <div v-for="key in configKeys" :key="key.id" class="list-group-item">
        {{ key.config_key }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import ConfigKeysForm from "@/views/admin/ConfigKeysForm.vue";
import StaticAlert from "@/components/Utils/StaticAlert.vue";

const store = useStore()
const message = ref('')
const configKeys = computed(() => store.getters['admin/configKeys'])


loadConfig()

function loadConfig() {
  store.dispatch('admin/getConfigKeys')
      .then(keys => console.log(keys))
      .catch(error => {
        message.value = error.response?.data || error.message || error
        //alert(e.message || JSON.stringify(e))
      })
}
</script>

<style lang="scss" scoped>

</style>
