<template>
  <div v-if="store.state.eshop.store">
    <store-nav />
    <div id="main-content" class="container container-container bg-white">
      <div id="main" class="pt-3 px-0 px-sm-5 pb-5 mb-5">
        <router-view />
      </div>
    </div>
    <app-modal :modal-in="!validEbayRefresh">
      <template #header>Authorizing...</template>
      <ebay-callback :importable="false" />
    </app-modal>
  </div>
</template>

<script setup>
import StoreNav from "@/components/StoreNav.vue";
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from "vue-router";
import EbayCallback from "@/views/EbayCallback.vue";
import AppModal from "@/components/Utils/AppModal.vue";

const store = useStore()
const router = useRouter()

const eshop = computed(() => store.state.eshop.store)
const validEbayRefresh = computed(() => store.getters['eshop/validEbayRefreshToken'])

if (!eshop.value) {
  router.replace({name: 'Home'})
}

</script>
