<template>
  <div>
    <h2>Top Orders</h2>
    <static-alert v-if="message" :message="message" @on-hide="message=''" />
    <div class="lead bg-success-subtle border-top border-success border-bottom p-2 mb-3 d-flex align-items-center unmargin">
      <div class="me-auto"><i class="bi bi-receipt"></i> {{ totalOrderCount }} Orders</div>
      <div class="mx-3">
        <input type="date" class="form-control mx-3" v-model="dateRange" @change="getOrders" />
      </div>
      <button type="button" class="btn btn-primary mx-3" @click="presentationMode=true">
        <i class="bi bi-camera-reels me-2"></i> Present
      </button>
      <div class="ms-auto">
        Net Sales: <strong class="d-inline-block"><money-block :dollar="totalOrderValue" /></strong>
      </div>
    </div>
    <div class="h-page">
      <transition mode="out-in">
        <div v-if="fetching" class="h-page d-flex justify-content-center align-items-center">
          <loading-spinner  />
        </div>

        <div v-else-if="pageList[currentOrder]" class="h-page">
          <div v-for="order in pageList" :key="order.oid" class="pb-3 mb-3 border-bottom d-flex">
            <div class="me-5">{{ order.trans_date }}</div>
            <div class="me-auto">{{ order.oid }}</div>
            <div class="mx-3">{{ order.types }}</div>
            <div class="ms-auto">{{ order.net }}</div>

          </div>

          <app-modal :modal-in="presentationMode" @close="presentationMode=false">
            <div class="d-flex mb-3 align-items-center">
              <button class="btn btn-light btn-outline-primary me-auto" @click="nextOrder(-1)">
                <i class="bi bi-chevron-left" title="Previous Order"></i>
              </button>
              <h2 class="mx-3"># {{ currentOrder + 1 }}</h2>
              <button class="btn btn-light btn-outline-primary ms-auto" @click="nextOrder(1)">
                <i class="bi bi-chevron-right" title="Next Order"></i>
              </button>
            </div>
            <transition mode="out-in">
              <ebay-order :order-id="pageList[currentOrder].oid" :key="currentOrder" :discreet="true" />
            </transition>
          </app-modal>

        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from "vue"
  import { useStore } from "vuex"
  import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";
  import MoneyBlock from "@/components/Utils/MoneyBlock.vue";
  import StaticAlert from "@/components/Utils/StaticAlert.vue";
  import EbayOrder from "@/components/ebay/EbayOrder.vue";
  import AppModal from "@/components/Utils/AppModal.vue";

  const store = useStore()
  const orderList = ref([])
  const fetching = ref(false)
  const message = ref('')
  const currentOrder = ref(0)
  const dateRange = ref(new Date().toISOString().slice(0, 10))
  const presentationMode = ref(false)

  const pageList = computed(() => {
    if (!orderList.value) return []

    let endDate = new Date(dateRange.value)
    // endDate = new Date(endDate.toLocaleString("en-US", {
    //   timeZone: "America/Chicago"
    // }));
    let startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 6);

    return orderList.value.slice()
        .filter(o => {
          const d = new Date(o.trans_date)
          return d <= endDate && d > startDate
        })
        .sort((a, b) => parseFloat(b.net) - parseFloat(a.net))
        .slice(0,10)
  })
  const totalOrderCount = computed(() => pageList.value.length || 0)
  const totalOrderValue = computed(() => pageList.value.reduce((acc, item) => acc + parseFloat(item.net), 0.0))

  // pre load page
  getOrders()

  function getOrders() {
    fetching.value = true
    store.dispatch("eshop/getOrders")
        .then(orders => {
          orderList.value = orders
          currentOrder.value = pageList.value.length - 1
          fetching.value = false
        })
        .catch(error => {
          message.value = error.response?.data || error.message || error
          fetching.value = false
          console.log(error)
        })
  }

  function nextOrder(dir) {
    let newVal = currentOrder.value + dir
    if (newVal < 0) newVal = pageList.value.length - 1
    if (newVal >= pageList.value.length) newVal = 0
    currentOrder.value = newVal
  }
</script>

<style lang="scss" scoped>

</style>
