<template>
  <div>
    <button type="button" class="btn btn-primary d-none" @click="getSellerItems()">Fetch eBay Data</button>
    <static-alert v-if="message" :message="message" @on-hide="message=''" />
    <transition mode="out-in">
      <loading-spinner v-if="loading" />
      <div v-else-if="items">
        <div class="lead">{{ fetchDetails.PaginationResult.TotalNumberOfEntries }} Active Items</div>
        <paginator-bar :total-items="fetchDetails.PaginationResult.TotalNumberOfEntries" :per-page="perPage" :page="page" @next-page="loadMore" />
        <div v-for="item in filtered"
            :key="item.ItemID"
            :class="{'bg-success-subtle': selectedItem === item}"
        >
          <div v-if="item.Description">
            <ebay-item-list-detail :item="item" />
          </div>
          <div v-else-if="item.ItemID" class="mb-3">
            <ebay-item :line="{legacyItemId:item.ItemID}" />
          </div>
          <div v-else class="border-bottom d-flex" @click="selectItem(item)" role="button">
            {{  item.ItemID  }}
          </div>

        </div>

        <paginator-bar :total-items="fetchDetails.PaginationResult.TotalNumberOfEntries" :per-page="perPage" :page="page" @next-page="loadMore" />

      </div>
    </transition>
  </div>
</template>

<script setup>

import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue"
import {ref, computed, nextTick} from 'vue'
import { useStore } from 'vuex'
import EbayItem from "@/components/ebay/EbayItem.vue";
import EbayItemListDetail from "@/components/ebay/EbayItemListDetail.vue";
import PaginatorBar from "@/components/Utils/PaginatorBar.vue";
import StaticAlert from "@/components/Utils/StaticAlert.vue";

const loading = ref(false)
const message = ref('')
const items = ref(null)
const fetchDetails = ref(null)
const selectedItem = ref(null)
const page = ref(1)
const perPage = ref(10)
const store = useStore()
const filtered = computed(() => {
  if (!items.value) return []
  const start = (page.value - 1) * perPage.value
  const end = page.value * perPage.value
  return items.value.slice()
      .sort((a, b) => new Date(b.ListingDetails.StartTime) - new Date(a.ListingDetails.StartTime))
      .slice(start, end)
})

function selectItem(item) {
  if (item === selectedItem.value) {
    selectedItem.value = null
    return
  }
  selectedItem.value = item
}

async function loadMore(dir) {
  loading.value = true
  page.value += dir
  await nextTick()
  loading.value = false
}

getSellerItems()
function getSellerItems() {
  loading.value = true
  const granularity = null // 'Coarse'
  store.dispatch('eshop/getSellerItems', {granularity: null, limit: 10, page: page.value })
      .then(list => {
        items.value = list.ItemArray.Item || []
        fetchDetails.value = { ...list, ItemArray: [], HasMoreItems: granularity ? list.HasMoreItems : true }
        loading.value = false
      })
      .catch(error => {
        message.value = error.response?.data || error.message || error
        loading.value = false
        console.log('getSellerItems error', error)
      })
}
</script>

<style lang="scss" scoped>

</style>
