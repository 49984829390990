<template>
  <div>
    <static-alert v-if="message" :message="message" @on-hide="message=''" />
    <transition mode="out-in">
      <div v-if="loading" class="small text-center text-muted">
        <loading-spinner :small="true" class="me-3" /> refreshing eBay Data...
      </div>

      <div v-else>
        <div class="small text-center text-muted mb-2">
          last import: {{ lastDate.toLocaleString() }} ({{ importDate.toLocaleString() }})
          <button type="button" class="btn btn-sm btn-light ms-2" @click="getEbayTransactions" title="refresh data">
            <i class="bi bi-arrow-clockwise"></i>
          </button>
        </div>

      </div>
    </transition>
  </div>
</template>

<script setup>

import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";

import { ref, defineProps, defineEmits, computed } from 'vue'
import { useAuth0 } from "@auth0/auth0-vue"
import { useStore } from "vuex"
import StaticAlert from "@/components/Utils/StaticAlert.vue";

const auth0 = useAuth0()
const store = useStore()

const props = defineProps({
  transactionType: {
    type: String,
    required: false,
    default: 'ALL'
  }
})

const emits = defineEmits(['onUpdate'])

const dates = ref('')
const inputs = ref([])
const loading = ref(false)
const message = ref('')
const fdate = new Date()
fdate.setDate((new Date()).getDate() - 1)
const importDate = ref(fdate)

const lastDate = computed(() => store.state.eshop.ebayCheckDate)

getLastImportDate()

function getLastImportDate() {
  loading.value = true
  store.dispatch("eshop/getLastImportDate")
      .then(() => {
        getEbayTransactions()
      })
      .catch(error => {
        message.value = error.response?.data || error.message || error
        console.log(error)
      })
}

function getEbayTransactions() {
  inputs.value = []
  loading.value = true

  // const fdate = lastDate.value
  const fdate = importDate.value
  // const fdate = new Date()
  // fdate.setDate(fdate.getDate() - 1)
  // const tdate = new Date()
  // dates.value = `${ fdate.toISOString() }..${ tdate.toISOString() }`
  dates.value = `${ fdate.toISOString() }..`

  store.dispatch('eshop/getEbayData', {
    auth0,
    type: props.transactionType || 'ALL',
    dates: dates.value //'2024-09-07T05:00:01.000Z..2024-09-09T05:00:01.000Z'
  })
      .then(() => {
        emits('onUpdate')
        loading.value = false
      })
      .catch(error => {
        loading.value = false
        message.value = error.response?.data || error.message || error
        console.log('getEbayData error', error)
      })
}

</script>
