<template>
  <div class="mb-2 p-2 bg-info-subtle text-center small d-flex align-items-center">
    <button type="button" @click="nextPage(-1)" class="btn btn-primary me-auto" :disabled="prevDisabled">Prev Page</button>
    <div class="mx-3 small">page {{ page }} of {{ Math.floor(totalItems/perPage) }} pages</div>
    <button type="button" @click="nextPage(1)" class="btn btn-primary ms-auto" :disabled="nextDisabled">Next Page</button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue"
const props = defineProps({
  page: {
    type: Number,
    required: true,
    default: () => 1
  },
  perPage: {
    type: Number,
    required: true,
    default: () => 15
  },
  totalItems: {
    type: Number,
    required: true,
    default: () => 0
  }
})
//const propRef = toRef(props, 'pages')
const emits = defineEmits(['nextPage'])
const prevDisabled = computed(() => props.page <= 1)
const nextDisabled = computed(() => props.page >= Math.floor(props.totalItems / props.perPage))

function nextPage(dir) {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
  emits('nextPage', dir)
}
</script>

<style lang="scss" scoped>

</style>
