import { createStore } from 'vuex'
import admin from './adminStore'
import eshop from './eshopStore'
import AxiosService from "@/services/AxiosService";
import {useAuth0} from "@auth0/auth0-vue";
const api = new AxiosService(process.env.NODE_ENV === 'production' ? '/api' : '/api');

const store = createStore({
  modules: {
    admin,
    eshop,
  },
  state: {
    initialized: false,
    appName: 'eShopCommand',
    tagLine: 'Simple Reporting for Resellers',
    modalIn: false,
    roles: [],
    api: null,
  },
  getters: {
    modalIn: state => state.modalIn,
    hasRole: state => role => {
      return state.roles.indexOf(role) !== -1
    }
  },
  mutations: {
    setApi(state, token) {
      state.api = api.setToken(token)
    },
    initialize (state) {
      state.initialized = true
    },
    closeModal (state) {
      state.modalIn = false
    },
    setToken(state, token) {
      state.token = token
      if (token) {
        sessionStorage.setItem('ec-token', token)
      } else {
        sessionStorage.removeItem('ec-token')
      }
    },
    setUser(state, user) {
      state.user = user
    },
    setRoles(state, roles) {
      state.roles = roles || []
    },
    toggleModal(state, inStatus) {
      state.modalIn = !!inStatus
    }
  },
  actions: {
    logOut({ commit }) {
      commit('setToken', null)
      commit('setStores', [])
      commit('setStore', null)
      commit('setUser', null)
      return Promise.resolve()
    }
  }
})

store.subscribeAction({
  before: async (action, state) => {
    const auth0 = await useAuth0()
    //const { isAuthenticated, getAccessTokenSilently } = useAuth0()
    if (auth0?.isAuthenticated) {
      const token = await auth0?.getAccessTokenSilently()
      state.api = api.setToken(token)
    }
  }
})

export default store
