<template>
  <div class="fixed-top store-nav bg-success-subtle border-top border-primary border-bottom p-0 m-0">
    <div class="xcontainer xcontainer-container">
      <ul class="nav nav-pills nav-fill">
        <li class="nav-item" v-for="nav in navItems" :key="nav.name">
          <router-link
              :to="{name:nav.name}"
              class="nav-link rounded-0 d-flex align-items-center justify-content-center flex-column flex-sm-row"
              :class="{active:$route.name === nav.name, disabled:!isStore}"
              :title="nav.label"
          >
            <i class="bi me-0 me-sm-2" :class="nav.icon"></i>
            <span class="store-nav-label">{{ nav.label }}</span>
          </router-link>

        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const isStore = computed(() => !!store.state.eshop.store)
const role = computed(() => store.state.eshop.store?.role)

const navItems = computed (() => {
  const nav = [
    {name: 'Dashboard', label: 'Dashboard', icon: 'bi-speedometer2'},
    {name: 'Orders', label: 'Orders', icon: 'bi-cart2'},
    {name: 'Accounting', label: 'Transactions', icon: 'bi-card-list'},
    {name: 'Inventory', label: 'Inventory', icon: 'bi-boxes'}
  ]
  if (role.value === 'OWNER' || role.value === 'ADMIN') {
    nav.push({name: 'Settings', label: 'Settings', icon: 'bi-gear'})
  }
  return nav
})

</script>

<style lang="scss" scoped>
.nav-link.disabled {color: #ccc !important; cursor: not-allowed; font-style: italic;}
.nav-tabs {
  transform: translateY(1px);
}
//.router-link-active {
//  border-bottom:1px solid white;
//}
.store-nav {top: 70px;}


@media only screen and (hover: none) and (pointer: coarse) {
  .store-nav-label {
    display: block;
    font-size: 0.5rem;
  }
  .bi {height:2.5rem; font-size: 1.7rem;}
  .store-nav {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: auto;
    z-index: 1030;
    margin-top:0;
    border-bottom-width: 1rem !important;
  }
  //.nav-link {padding-bottom: 2rem;}
}
</style>
