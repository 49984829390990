<template>
  <div class="home">
    <h1 class="d-none">Dashboard</h1>

    <static-alert v-if="errors" :message="errors" @on-hide="errors=''" />

    <h2>eBAY SALES</h2>
    <sales-report class="mb-3" />

    <h2>CASH ASSETS</h2>
    <div class="mb-3 row">
      <div v-for="account in assets" :key="account.id"  class="col-12 col-sm-6">
        <account-card :account="account" :expanded="true" />
      </div>

    </div>

    <h2>GROSS INCOME</h2>
    <div class="mb-3 row">
      <div v-for="account in incomes" :key="account.id"  class="col-12 col-sm-6">
        <account-card :account="account" :expanded="true" />
      </div>
    </div>

    <h2>EXPENSES</h2>

      <div class="chart-container mb-3">
        <apexchart
            :options="chartOptions"
            :series="series"
            class="mx-auto"
        ></apexchart>
      </div>

      <div class="row">
        <div v-for="account in expenses" :key="account.id" class="col-12 col-sm-6">
          <account-card :account="account" :expanded="true" />
        </div>
      </div>

  </div>
</template>

<script setup>

import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import AccountCard from "@/components/AccountCard.vue";
import SalesReport from "@/components/SalesReport.vue";
import StaticAlert from "@/components/Utils/StaticAlert.vue";

const store = useStore()
const errors = ref('')
const eshopStore = computed(() => store.state.eshop.store)
//const storeName = computed(() => eshopStore.value && eshopStore.value.name || '-')
const waveStoreId = computed(() => store.getters['eshop/config']('WAVE_STORE_ID'))
const accounts = computed(() => {
  const a = eshopStore.value.accounts
  if (!a) return []
  return a.filter(a => parseFloat(a.balance) !== 0)
      //.sort((a, b) => b.type.value.localeCompare(a.type.value) ) // a.id === (eshopStore.value.config.WAVE_ANCHOR_ACCOUNT_ID || '') ? 0 :
})
// const main = computed(() => accounts.value.filter(a => a.id === eshopStore.value.config.WAVE_ANCHOR_ACCOUNT_ID || ''))
const assets = computed(() => accounts.value.filter(a => a.type.value === 'ASSET'))
const incomes = computed(() => accounts.value.filter(a => a.type.value === 'INCOME'))
const expenses = computed(() => accounts.value.filter(a => a.type.value === 'EXPENSE'))

const chartOptions = computed(() => ({
  labels: expenses.value.map(e => e.name),
  chart: {width: '100%', type: 'pie'}
}))
const series = computed(() => expenses.value.map(e => parseFloat(e.balance)))


function getWaveData() {
  if (!waveStoreId.value) {
    errors.value = 'You need to set up a Wave Account and add ID in Settings'
    return
  }
  store.dispatch('eshop/getWaveAccounts', 0)
      .catch(e => errors.value = 'getWaveAccounts error: ' + e.message)
}

getWaveData()


</script>

<style lang="scss" scoped>
.chart-container { max-width: 500px; margin:0 auto;}
</style>
