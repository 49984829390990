<template>
  <transition mode="out-in">
    <div v-if="options">
      <div v-for="key in configs" :key="key" class="mb-3">
        <config-edit :data-key="key" :options="options" />
      </div>
    </div>
  </transition>
</template>

<script setup>

import ConfigEdit from "@/components/Utils/ConfigEdit.vue";
import {computed, ref} from "vue"
import {useStore} from "vuex"
const store = useStore()
const options = ref()
const configs = computed(() => store.state.eshop.globalConfigs.filter(c => c.substr(0, 4) === 'ESC_'))

store.dispatch('eshop/getAccounts')
    .then(acc => options.value = acc)
    .catch(e => console.log(e))
</script>

<style lang="scss" scoped>

</style>
