<template>
  <div>
    <button type="button" class="btn btn-light btn-outline-primary" @click="show=true">
      <i class="bi bi-plus-circle me-2"></i>
      Add Transaction
    </button>
    <app-modal :modal-in="show" @close="show=false">
      <template #header>Add Transaction</template>
      <div>Here's the form to add/edit transaction</div>
    </app-modal>
  </div>
</template>

<script setup>
import AppModal from "@/components/Utils/AppModal.vue";
import { ref } from "vue"
const show = ref(false)
</script>

<style lang="scss" scoped>

</style>
