<template>
  <div class="accordion-item">
    <h2 class="accordion-header" :id="`${id}-header`">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#${id}`" aria-expanded="false" :aria-controls="`#${id}`" @click="show=true">
        <img v-if="iconDomain" :src="`http://www.google.com/s2/favicons?domain=${iconDomain}`" height="16" width="16" class="me-2" />
        {{ label }}
      </button>
    </h2>
    <div :id="id" class="accordion-collapse collapse">
      <div class="accordion-body">
        <transition name="fade" mode="out-in">
         <slot v-if="show"></slot>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue"
defineProps(["label", "id", "iconDomain"]);
const show = ref(false)
</script>

<style lang="scss" scoped>

</style>
