<template>
  <div>
    <h1 class="d-none">Accounting</h1>

    <div>
      <div class="d-flex">
        <h2 class="me-3">eBay Transactions</h2>
        <a v-if="waveStoreId" :href="`https://next.waveapps.com/${waveStoreId}/transactions`" class="mx-auto" target="_blank">Wave Accounting</a>
        <transaction-add-button class="ms-auto" />
      </div>


      <static-alert v-if="errors" :message="errors" @on-hide="errors=''" />



      <local-transactions />

    </div>
  </div>
</template>

<script setup>

import { computed } from 'vue'
import { useStore } from "vuex"

import LocalTransactions from "@/components/TransactionList.vue";
import StaticAlert from "@/components/Utils/StaticAlert.vue";
import TransactionAddButton from "@/components/TransactionAddButton.vue";
const store = useStore()
const waveStoreId = computed(() => store.getters['eshop/config']('WAVE_STORE_ID'))


</script>
