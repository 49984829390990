<template>
  <div>
    <a :href="ebayUrl" class="btn btn-primary btn-lg">
      <i class="bi bi-arrow-left-right"></i> Connect eBay Store
    </a>
  </div>
</template>

<script setup>

import { ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const ebayUrl = ref(null)

store.dispatch('eshop/getEbayAuthUrl')
    .then(url => {
      ebayUrl.value = url
    })
    .catch(e => console.error(e))

</script>
