<template>

    <button type="button" class="ms-auto btn btn-warning" @click.prevent="logoutUser">
      Logout
    </button>

</template>

<script setup>
import { useAuth0 } from '@auth0/auth0-vue';
import {computed} from "vue";
const { logout } = useAuth0();
const url = computed(() => `${window.location.origin}/logout`)

const logoutUser = () => logout({
  logoutParams: {
    returnTo: url.value
  }
})
</script>
