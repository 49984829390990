<template>
  <div class="container-fluid position-relative image-gallery" @click="onNextImage" role="button">
    <transition-group mode="out-in">
<!--      <img v-for="(image, idx) in images" :key="idx" :src="image" v-show="currentIndex===idx" class="img-fluid border rounded" title="playing">-->
      <img
          v-if="images.length && images[currentIndex]"
          :key="currentIndex"
          :src="images[currentIndex]"
          @load="queueNext"
          class="img-fluid">
    </transition-group>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue"
const props = defineProps({
  autoPlay: {
    type: Boolean,
    default: false
  },
  images: {
    type: Array,
     default: () => []
  }
})
const currentIndex = ref(0)
const playing = ref(props.autoPlay)
const player = ref()

function queueNext() {
  if (!playing.value) return;
  player.value = setTimeout(nextImage, 3000)
}

function onNextImage() {
  playing.value = false
  if (player.value) clearTimeout(player.value)
  nextImage()
}

function nextImage() {
  let nextIndex = currentIndex.value + 1
  if (nextIndex >= props.images.length) nextIndex = 0
  if (props.images.length && nextIndex < 0) nextIndex = props.images.length - 1
  currentIndex.value = nextIndex
}
</script>

<style lang="scss" scoped>
  .image-gallery {
    height:0;
    padding-top: 100%;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top:0; left:0;
    }
  }
</style>
