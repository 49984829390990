<template>
  <div>
    <h2>All Orders</h2>
    <static-alert v-if="message" :message="message" @on-hide="message=''" />
    <div class="lead bg-success-subtle border-top border-success border-bottom p-2 d-flex align-items-center unmargin">
      <div class="me-3"><i class="bi bi-receipt"></i> {{ totalOrderCount }} Orders</div>
      <div class="ms-auto">
        Net Sales: <strong class="d-inline-block"><money-block :dollar="totalOrderValue" /></strong>
      </div>
    </div>
    <transition mode="out-in">
      <loading-spinner v-if="fetching" />
      <table v-else class="table table-hover">
        <thead>
        <tr>
          <th scope="col" class="col-3">Date</th>
          <th scope="col" class="col-4">Order ID</th>
          <th scope="col" class="col-5">Transactions</th>
        </tr>
        <tr>
          <td colspan="3">
            <paginator-bar :page="page" :perPage="perPage" :total-items="totalOrderCount" @next-page="nextPage" />
          </td>
        </tr>
        </thead>
        <tbody>
          <order-list-item :order="order" v-for="order in pageList" :key="order.oid"/>
        </tbody>
        <tfoot>
        <tr><td colspan="3" class="text-center">
          <paginator-bar :page="page" :perPage="perPage" :total-items="totalOrderCount" @next-page="nextPage" />

        </td></tr>
        </tfoot>
      </table>
    </transition>

  </div>
</template>

<script setup>
  import { ref, computed, nextTick } from "vue"
  import { useStore } from "vuex"
  import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";
  import OrderListItem from "@/components/OrderListItem.vue";
  import MoneyBlock from "@/components/Utils/MoneyBlock.vue";
  import PaginatorBar from "@/components/Utils/PaginatorBar.vue";
  import StaticAlert from "@/components/Utils/StaticAlert.vue";

  const store = useStore()
  const orderList = ref([])
  const fetching = ref(false)
  const page = ref(1)
  const perPage = ref(15)
  const message = ref('')

  const totalOrderCount = computed(() => orderList.value.length || 0)
  const totalOrderValue = computed(() => orderList.value.reduce((acc, item) => acc + parseFloat(item.net), 0.0))

  const pageList = computed(() => {
    if (!orderList.value) return []
    const start = (page.value - 1) * perPage.value
    const end = page.value * perPage.value
    return orderList.value.slice()
        //.sort((a, b) => new Date(b.ListingDetails.StartTime) - new Date(a.ListingDetails.StartTime))
        .slice(start, end)
  })

  async function nextPage(dir) {
    fetching.value = true
    page.value += dir
    await nextTick()
    fetching.value = false
  }

  getOrders()

  function getOrders() {
    fetching.value = true
    store.dispatch("eshop/getOrders")
        .then(orders => {
          orderList.value = orders
          fetching.value = false
        })
        .catch(error => {
          message.value = error.response?.data || error.message || error
          fetching.value = false
          console.log(error)
        })
  }
</script>

<style lang="scss" scoped>

</style>
