<template>
  <div>
    <static-alert v-if="message" :message="message" @on-hide="message=''" />
    <transition mode="out-in">
      <loading-spinner v-if="fetching" class="m-3" />
      <div v-else-if="validRefresh">
        <div class="lead mb-3">
          <i class="bi bi-check-circle text-success me-2"></i>
          eBay Account Authorized.
        </div>

        <ebay-shop-import v-if="!code && importable" @on-error="setMessage" />

      </div>
      <div v-else-if="code" class="mb-3">
        ebayCode: <pre class="small">{{ code }}</pre>
      </div>
      <div v-else class="mb-3">
        <connection-ebay />
      </div>
    </transition>
  </div>
</template>

<script setup>
import {computed, ref, defineProps} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useAuth0 } from "@auth0/auth0-vue";
import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";
import ConnectionEbay from "@/components/ebay/ConnectionEbay.vue";
import StaticAlert from "@/components/Utils/StaticAlert.vue";
import EbayShopImport from "@/components/ebay/EbayShopImport.vue";

const store = useStore()
const route = useRoute()
const auth0 = useAuth0()
const router = useRouter()

defineProps(['importable'])

const ebayUserToken = ref()
const validToken = ref(false)
const fetching = ref(false)
const message = ref('')
const validRefresh = computed(() => store.getters['eshop/validEbayRefreshToken'])
const code = route.query.code || null

if (code) {
  getToken()
} else {
  checkEbay()
}

function setToken(token) {
  ebayUserToken.value = token.token
  validToken.value = token.validToken
  fetching.value = false
  if (token.error) {
    message.value = token.error
    console.log('get token error', token.error)
  }
}

function setMessage(msg) {
  message.value = msg
}

function getToken() {
  store.dispatch('eshop/getEbayUserToken', {code, type: 'code'})
      .then(checkEbay)
    .catch(error => {
      message.value = error.response?.data || error.message || error
      setToken({validToken: false, token:null, error})
    })
}

function checkEbay() {
  fetching.value = true
  store.dispatch("eshop/getEbayRefreshExpires", { auth0 })
    .then(async () => {
      fetching.value = false
      if (code) await router.replace({name: 'Welcome'})
    }).catch(error => {
      message.value = error.response?.data || error.message || error
      fetching.value = false
    })
}
</script>

<style scoped>

</style>
