<template>
  <div>
    <a :href="wavesUrl" class="btn btn-primary btn-lg">
      <i class="bi bi-arrow-left-right"></i> Connect waveapps
    </a>
  </div>
</template>

<script setup>

import {useStore} from "vuex";
import {ref} from "vue";

const store = useStore()
const wavesUrl = ref()

store.dispatch('eshop/getOauthAuthUrl', {
  oauth_vendor: 'waveapps'
})
    .then(url => {
      wavesUrl.value = url
    })
    .catch(e => console.error(e))

</script>
