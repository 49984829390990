<template>
  <Teleport to="body">
    <transition name="slide-fade" mode="out-in">
      <div v-if="localMessage" class="p-3 text-white alert-message" @click="hide">
        <span class="bi bi-info-circle lead me-2"></span>
        {{ localMessage }}
      </div>
    </transition>
  </Teleport>
</template>

<script setup>

import { defineProps, defineEmits, ref, nextTick } from "vue"
const props = defineProps(['message'])
const localMessage = ref('')
const emits = defineEmits(["onHide"])
const timer = ref(null)

load()

async function load() {
  await nextTick()
  localMessage.value = props.message
  timer.value = setTimeout(hide, 5000)
}

function hide() {
  localMessage.value = ''
  setTimeout(() => emits('onHide'), 400)
}


</script>

<style lang="scss" scoped>
.alert-message {position:absolute; top:10px; left:0; width:100%; background:rgba(0,0,0,0.8); z-index:10000;}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}
</style>
