


<template>
  <div>
    <h1>eShops</h1>

    <transition mode="out-in">
      <loading-spinner v-if="loading" />
      <div v-else-if="eshop">
        {{ eshop }}
        <store-list />
      </div>
      <div v-else-if="loaded" class="d-flex align-baseline justify-content-center p-5">
        <ebay-callback :importable="true" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import StoreList from '@/components/StoreList.vue'
import LoadingSpinner from '@/components/Utils/LoadingSpinner.vue'
import { ref, nextTick, computed, watch } from 'vue'
import { useRouter } from "vue-router";
import { useStore } from 'vuex'
import EbayCallback from "@/views/EbayCallback.vue";

const store = useStore()
const router = useRouter()
const eshop = computed(() => store.state.eshop.store)
const loading = ref(false)
const loaded = ref(false)

watch(eshop, (newVal) => {
  if (newVal && newVal.id) {
    router.push({name: 'Dashboard', params: {storeKey: newVal.id}})
  }
})

loadStore()

async function loadStore() {
  loading.value = true
  await nextTick()
  store.dispatch('eshop/loadStore', {} )
      .then(() => {
        loading.value = false
        loaded.value = true
      })
      .catch(e => {
        loading.value = false
        alert(e.message)
      })
}




</script>

<style scoped>
.home-content {
  width: 500px; max-width: 100%;
}
</style>
