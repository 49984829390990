<template>
  <div v-if="address">
    <div v-if="!discreet">
      <div>{{ address.fullName }}</div>
      <div>{{ address.contactAddress?.addressLine1 }}</div>
      <div>{{ address.contactAddress?.addressLine2 }}</div>
    </div>
    <div>
      {{ address.contactAddress?.city }},
      {{ address.contactAddress?.stateOrProvince }}
      {{ address.contactAddress?.postalCode }}
    </div>
    <div>{{ address.contactAddress?.countryCode }}</div>

    <dl v-if="!discreet">
      <dt>Phone</dt>
      <dd>{{ address.primaryPhone?.phoneNumber }}</dd>
      <dt>Email</dt>
      <dd class="small"><a :href="`mailto:${address.email}`" class="small">{{ address.email }}</a></dd>
    </dl>

  </div>
</template>

<script setup>
import { defineProps, ref } from "vue"
const props = defineProps(['address', 'discreet'])
const address = ref(props.address)
</script>

<style lang="scss" scoped>

</style>
