<template>
  <div>
    <waveapps-callback class="mb-3" @on-auth="onAuth" />

    <transition mode="out-in">
      <div v-if="authorized">
        <div v-for="key in configs" :key="key" class="mb-3">
          <config-edit :data-key="key" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { useStore } from "vuex"
import ConfigEdit from "@/components/Utils/ConfigEdit.vue";
import {computed, ref} from "vue";
import WaveappsCallback from "@/views/WaveappsCallback.vue";

const store = useStore()
const configs = computed(() => store.state.eshop.globalConfigs.filter(c => c.substr(0, 5) === 'WAVE_'))
const authorized = ref(false)

function onAuth(auth) {
  console.log('auth', auth)
  authorized.value = !!auth
}

store.dispatch('eshop/getWaveAccounts', 0)
    .catch(e => console.log(e))


</script>

<style lang="scss" scoped>

</style>
